// Tabs Prefixes
export const manageTab = '/manage';
export const gatherTab = '/gather';
export const createTab = '/create';
export const reportTab = '/report';

export const organizationsPrefix = '/organizations';
export const usersPrefix = '/users';

// Root
export const Root = '/';

// Dashboard
export const AnnotationsPath = '/dashboard/annotations';
export const LabelFiltersPath = '/dashboard/manage_label_filters';

// Dashboard V4
// Note: following 2 routes are being suppressed temporary; not deprecating the feature
export const DashboardSocialPathV4 = '/dashboardv4/social';
export const DashboardEarnedPathV4 = '/dashboardv4/earned';

// Dashboard - Plan
// export const PlanPath = '/plan';
// Dashboard - Optimize
// export const OptimizePath = '/optimize';

// Dashboard - Insight
export const InsightPath = '/insight';

// Dashboard - Causal
export const CausalPath = '/causal';

export const ObserveEventsPathV4 = '/observe/events';
export const ObservePredictionsPathV4 = '/observe/predictions';
export const AdobeAnalyticsManagePredictionEventsPath = `/predictions/manage_prediction_events`;

// Create - Social
export const ShortlinkPath = `${createTab}/shortlink`;

// Create - Print
export const PrintPath = `${createTab}/print`;

// Create - Media
export const MediaPath = `${gatherTab}/media`;
export const MediaImagesPath = `${gatherTab}/media/images`;
export const MediaStockPhotosPath = `${gatherTab}/media/stockphotos`;
export const MediaVideosPath = `${gatherTab}/media/videos`;
export const MediaSharedPath = `${gatherTab}/media/shared`;
export const MediaSharedContainerPath = `${gatherTab}/media/shared/container`;
export const MediaSharedPublicPath = `${gatherTab}/media/shared/public`;
// additional media routes are in the MediaLayout component - need to be in there to pass props to children.

// Analyze
export const AnalyticsPath = `${reportTab}/analytics`;
export const SocialAnalyticsPath = `${AnalyticsPath}/dashboard/social`;
export const ShortlinksAnalyticsPath = `${AnalyticsPath}/dashboard/shortlinks`;
export const EarnedMediaAnalyticsPath = `${AnalyticsPath}/dashboard/earned_media`;
export const EarnedMediaAnalyticsPathMatch = `${AnalyticsPath}/dashboard/earned_media/:reportId`;
export const CreateEarnedMediaAnalyticsPath = `${AnalyticsPath}/dashboard/create_earned_media`;
export const CreateEarnedMediaAnalyticsPathMatch = `${AnalyticsPath}/dashboard/create_earned_media/:reportId`;
export const ResurgenceAnalyticsPath = `${AnalyticsPath}/dashboard/resurgence`;
export const ResurgenceAnalyticsPathMatch = `${AnalyticsPath}/dashboard/resurgence/multi_post`;
export const OrganizationTreePath = `${AnalyticsPath}/dashboard/organization_tree`;
export const HypeAndFlowAnalyticsPath = `${AnalyticsPath}/dashboard/hypeandflow`;
export const ShareOfVoiceAnalyticsPath = `${AnalyticsPath}/dashboard/competitive_intelligence`;
export const ShareOfVoiceAnalyticsPathMatch = `${AnalyticsPath}/dashboard/competitive_intelligence/:reportId`;
export const CostToRecreateAnalyticsPath = `${AnalyticsPath}/dashboard/media_valuation`;
export const CreateCostToRecreateAnalyticsPath = `${AnalyticsPath}/dashboard/create_media_valuation`;
export const CostToRecreateAnalyticsPathMatch = `${AnalyticsPath}/dashboard/media_valuation/:reportId`;
export const WebAndAppAnalyticsPath = `${AnalyticsPath}/dashboard/web_apps`;
export const ThirdPartyMediaAnalyticsPath = `${AnalyticsPath}/dashboard/third_party_media`;
export const ThirdPartyMediaAnalyticsSettingsPath = `${AnalyticsPath}/dashboard/third_party_media/settings`;
export const CreateThirdPartyMediaAnalyticsPath = `${AnalyticsPath}/dashboard/create_third_party_media`;
export const CreateThirdPartyMediaAnalyticsPathMatch = `${AnalyticsPath}/dashboard/create_third_party_media/:reportId`;
export const ProgramLiftPath = `${AnalyticsPath}/dashboard/program_lift`;
export const ProgramLiftAnalyticsPath = `${AnalyticsPath}/dashboard/program_lift/:reportId`;
export const SalesforcePath = `${AnalyticsPath}/dashboard/salesforce`;
export const TargetingAnalyticsPath = `${AnalyticsPath}/dashboard/targeting`;

// Manage
export const OrganizationPath = `${manageTab}/organization`;

export const ManageUsersPath = `${manageTab}/users`;

// Organizations
export const LinkAccountsPath = `${organizationsPrefix}/link`;
export const ManageOrganizationPath = `${organizationsPrefix}/manage`;
export const ThemesAndTopicsPath = `${organizationsPrefix}/themes`;

// Adobe Analytics
export const AdobeAnalyticsLinkAccountPath = `${organizationsPrefix}/link/adobe_analytics`;
export const LinkAdobeAnalyticsSegmentPath = `${organizationsPrefix}/link/adobe_analytics_segment`;

// Google Analytics
export const LinkGoogleAnalyticsFilterPath = `${organizationsPrefix}/link/google_analytics_filter`;

// Apple's App Store Connect
export const AppStoreConnectLinkAccountPath = `${organizationsPrefix}/link/app_store_connect`;

// Goole Play Console
export const GooglePlayConsoleLinkAccountPath = `${organizationsPrefix}/link/google_play_console`;

// Users
export const LoginPath = `${usersPrefix}/login`;
export const MFAInputPath = `${usersPrefix}/mfainput`;
export const RegisterPath = `${usersPrefix}/register-bab264b8129a8286ec`;
export const OnboardPath = `${usersPrefix}/onboard`;
export const EULAPath = `${usersPrefix}/eula`;
export const ConfirmEmailPath = `${usersPrefix}/confirm`;
export const ChangePasswordPath = `${usersPrefix}/changepw`;
export const UserProfilePath = `${usersPrefix}/profile`;
export const RecoverPath = `${usersPrefix}/recover`;
export const RecoverWithTokenPath = `${usersPrefix}/recover_use_token`;
export const BecomePath = `${usersPrefix}/become`;
export const InvitePath = `${usersPrefix}/invite`;
export const ResendEmailVerificationPath = `${usersPrefix}/resend_email_verification`;

// Edit
export const EditContainerPath = '/edit/container';

// Pages
export const AssociatedAccountsPath = '/pages/accounts';
